import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateCampaign } from './useCreateCampaign';
import { toast } from 'sonner';
import { uploadFile } from '@/services/utility.service';
import {
	createDraftCampaign,
	updateCampaignStatus,
	updateDraftCampaign,
} from '../../services/campaigns.service';
import { QUEST_URL } from '@/config';
import { Button } from '@/components/ui/button';
import {
	AdminInputType,
	CampaignRewardCategory,
	CampaignRewardType,
	ICreateTasks,
	IMetricBasedXpConfig,
	ITask,
	Status,
	TaskKeys,
} from '../../types';
import { useCreateCampaignValidate } from './useCreateCampaignValidate';
import { v4 as uuidv4 } from 'uuid';
import {
	MetricBasedXpFunction,
	TaskSectionUnitTypes,
} from '../../types/tasks.enums';
import {
	convertArrayToCommaSeparatedString,
	completeAndSanitizeUrl,
	deepCopy,
} from '@/utils/parsers';
import { handleErrorMessage } from '@/utils/notifications';
import { IEditingTasks } from './useCreateCampaignTasks';
import { queryClient } from '@/lib/react-query';
import { getTweetShareLink } from '@/utils/twitter';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { adjustedIntercomLauncherPosition } from '@/lib/utils';

interface ICampaignMutationBody {
	name: string;
	description: string;
	banner: string;
	startImmediately: boolean;
	noEndTime: boolean;
	startDate: Date;
	startDateTz: string;
	endDateTz: string;
	endDate: Date;
	reward: any[];
	channels?: any[];
	passwordProtected?: any;
	xpRestriction?: any;
	campaignsCompleted?: any;
	leaderboard?: any;
	sendAnnouncement?: any;
	recurrenceTz?: string;
	campaignCategory?: any;
	taskSections: any[];
	taskGroupList: any[];
	quest: {
		tasks: any[];
		referralTask: any;
	};
	tags: any[];
	twitterShare?: {
		text?: string;
		link?: string;
	};
	narrativeTexts?: {
		key: string;
		text: string;
	}[];
	seoMetadata?: {
		title: string;
		description: string;
		keywords: string;
		image: string;
	};
	bannerPosition: {
		x: number;
		y: number;
	};
}

export const useCreateCampaignMutations = () => {
	const { id } = useParams();
	const {
		details,
		rewards,
		tasks,
		setIsDirty,
		isUpdate,
		setDetails,
		setRewards,
		setShowLaunch,
		setIsUpdate,
		setErrors,
	} = useCreateCampaign();
	const { validate } = useCreateCampaignValidate();
	const navigate = useNavigate();

	const disclaimerToastIdRef = useRef(null);

	const [isLoading, setIsLoading] = useState({
		draft: false,
		launch: false,
		delete: false,
		test: false,
		preview: false,
	});

	const parseMetricBasedXpConfig = (config: IMetricBasedXpConfig) => {
		const parsedConfig: IMetricBasedXpConfig = {
			functionType: config.functionType,
		};

		if (config.linearMetadata) {
			parsedConfig.linearMetadata = {
				xpMultiplier: parseFloat(
					config?.linearMetadata?.xpMultiplier?.toString(),
				),
			};
		}

		if (config.tieredMetadata) {
			parsedConfig.tieredMetadata = {
				tiers: config.tieredMetadata.tiers.map((tier) => ({
					tierXp: parseFloat(tier?.tierXp?.toString()),
					minMetric: parseFloat(tier?.minMetric?.toString()),
					maxMetric: parseFloat(tier?.maxMetric?.toString()),
				})),
			};
		}

		if (config.sigmoidMetadata) {
			parsedConfig.sigmoidMetadata = {
				optimalMetricValue: parseFloat(
					config?.sigmoidMetadata?.optimalMetricValue?.toString(),
				),
				rateOfIncrease: parseFloat(
					config?.sigmoidMetadata?.rateOfIncrease?.toString(),
				),
			};
		}

		return parsedConfig;
	};

	const prepareBody = async () => {
		let banner: string;
		const rewards_ = [...rewards];
		const tags = [];

		if (details.bannerFile) {
			const bannerLink = await uploadResource(details.bannerFile, 'banner');
			banner = bannerLink as string;
			setDetails((prev) => ({
				...prev,
				bannerLink: banner,
				bannerFile: null,
			}));
		} else {
			setDetails((prev) => ({
				...prev,
				bannerLink: details.bannerLink || details.banner,
				bannerFile: null,
			}));
		}
		if (details.network) {
			if (details.isCustomChain) {
				tags.push(
					{
						key: 'possibleChainId',
						value: details.network,
					},
					{
						key: 'chain',
						value: details.chain,
					},
					{
						key: 'namespaceTag',
						value: details.namespaceTag,
					},
				);
			} else {
				tags.push(
					{
						key: 'chainId',
						value: details.network,
					},
					{
						key: 'chain',
						value: details.chain,
					},
					{
						key: 'namespaceTag',
						value: details.namespaceTag,
					},
				);
			}
		}
		const whitelistReward = rewards_.find(
			(i) => i.category === CampaignRewardCategory.Whitelist,
		);
		const officialPointsReward = rewards_.find(
			(i) => i.category === CampaignRewardCategory.OfficialPoints,
		);

		if (whitelistReward?.isActive) {
			const image = whitelistReward?.whitelistReward?.nftImage;
			if (image) {
				const nftImageLink = await uploadResource(image, 'whitelist');
				whitelistReward.whitelistReward.nftImage = null as any;
				whitelistReward.whitelistReward.image = nftImageLink as string;
				setRewards(rewards_);
			}
		}
		if (officialPointsReward?.isActive) {
			const image = officialPointsReward?.whitelistReward?.nftImage;
			if (image) {
				const nftImageLink = await uploadResource(image, 'whitelist');
				officialPointsReward.whitelistReward.nftImage = null as any;
				officialPointsReward.whitelistReward.image = nftImageLink as string;
				setRewards(rewards_);
			}
		}

		let seoImage: string;
		if (details?.seoMetadata?.imageFile && details.seoMetadata.isCustomImage) {
			const seoImageLink = await uploadResource(
				details?.seoMetadata?.imageFile,
				'seo',
			);
			seoImage = seoImageLink as string;
			setDetails((prev) => ({
				...prev,
				seoMetadata: {
					...prev.seoMetadata,
					image: seoImageLink as string,
					imageFile: null,
				},
			}));
		}

		const tasks_ = deepCopy(tasks)
			.map((i: IEditingTasks) => (i.type === 'task' ? i.task : i.tasks))
			.flat()
			.map((i: ICreateTasks) => {
				const task: ITask = {
					_id: i.taskId,
					name: i.name,
					description: i.description,
					slides: i.learningContent.slides,
					xp: i.xp,
					adminInputs: i.adminInputs?.map((i) => {
						let value = i.value;
						if (
							i.key === TaskKeys.DiscordInviteLink ||
							i.key === TaskKeys.TeleGramGroupLink ||
							i.key === TaskKeys.WebsiteUrl
						) {
							value = completeAndSanitizeUrl(value as string);
						}
						if (i.inputType === AdminInputType.InputStringArray) {
							return {
								...i,
								value: (
									value as { value: string; id: string }[]
								).map((i) => i.value),
							};
						}
						if (i.inputType === AdminInputType.InputMultiSelectAsync) {
							return {
								...i,
								value: convertArrayToCommaSeparatedString(
									value as string[],
								),
							};
						}
						if (i.inputType === AdminInputType.TwitterTags) {
							if (i.key === TaskKeys.TweetTagsRequired) {
								value = i.value ? i.value : 0;
							}
						}
						return {
							...i,
							value: value,
						};
					}),
					templateFamily: i.templateFamily,
					templateType: i.templateType,
					logo: i.logo,
					requiredLogins: i.requiredLogins,
					isRequiredTask: i.isRequiredTask,
					maxFailedCountConfig: i.appliedFeatures?.maxFailedCount?.enabled
						? i.maxFailedCountConfig
						: {
								maxFailedCount: 0,
								isMaxFailedCountEnabled: false,
								isDiminishingXpEnabled: false,
							},
					recurrenceConfig: i.appliedFeatures?.recurrance?.enabled
						? i.recurrenceConfig
						: {
								isRecurring: false,
								frequencyInDays: 0,
							},
					metricBasedXpConfig: i.metricBasedXpConfig
						? parseMetricBasedXpConfig(i.metricBasedXpConfig)
						: undefined,
					powVerifyConfig: i.powVerifyConfig,
					customInitiationURL: i.customInitiationURL,
				};
				return task;
			});

		const taskSections = [
			{
				_id: uuidv4(),
				title: 'default',
				isDefaultSection: true,
				displayData: tasks_.map((i: any) => ({
					unitType: TaskSectionUnitTypes.Task,
					unitId: i._id,
				})),
			},
		];

		const reward = rewards_
			?.filter((i) => i.isActive)
			?.map((i) => {
				const updatedReward = { ...i };

				if (i.category === CampaignRewardCategory.OfficialPoints) {
					updatedReward.category = CampaignRewardCategory.Whitelist;
					updatedReward.whitelistReward = {
						...i.whitelistReward,
						enableCustomPoints: true,
					};
				}

				if (i.method === CampaignRewardType.Unlimited) {
					updatedReward.isUnlimited = true;
					updatedReward.numRewards = null;
				}

				if (
					i.category === CampaignRewardCategory.Token &&
					i.customTieredLeaderboard
				) {
					delete updatedReward.tokenReward?.tokenAmountPerUser;
					updatedReward.tiers?.forEach((tier) => {
						delete tier.errors;
					});
				}

				return updatedReward;
			});

		const body: ICampaignMutationBody = {
			name: details.name || 'Untitled Campaign',
			description: details.description,
			startDate: details.startDate,
			startDateTz: details.timezone,
			endDate: details.endDate,
			bannerPosition: details.bannerPosition,
			endDateTz: details.timezone,
			startImmediately: details.startImmediately,
			noEndTime: details.noEndTime,
			reward: reward,
			taskSections,
			taskGroupList: [],
			quest: {
				tasks: tasks_,
				referralTask: details.referral,
			},
			banner,
			tags,
		};
		if (details.narrativeText) {
			if (!body.narrativeTexts) body.narrativeTexts = [];
			body.narrativeTexts.push({
				key: 'default',
				text: details.narrativeText,
			});
		}
		if (details.twitterShare) {
			if (!body.twitterShare) body.twitterShare = {};
			body.twitterShare.text = details.twitterShare;
			body.twitterShare.link = getTweetShareLink(
				details.twitterShare,
				`${QUEST_URL}/quest/${id}`,
			);
		}
		if (details.seoMetadata) {
			body.seoMetadata = {
				title: details.seoMetadata.title,
				description: details.seoMetadata.description,
				keywords: details.seoMetadata.keywords,
				image: details.seoMetadata.isCustomImage
					? seoImage
						? seoImage
						: details.seoMetadata.image
					: banner,
			};
		}
		return body;
	};

	const handleCampaignAction = async (
		actionType: 'launch' | 'test' | 'draft' | 'preview',
		isPreview?: boolean,
	) => {
		try {
			updateLoading(actionType, true);
			const isDraft = actionType === 'draft' || actionType === 'preview';
			if (!isDraft) {
				if (!(await validate())) {
					updateLoading(actionType, false);
					return;
				}
			}
			const body = await prepareBody();
			if (!body) {
				updateLoading(actionType, false);
				return;
			}
			let campaignId = id;
			if (isUpdate) {
				const res = await updateDraftCampaign(id, {
					campaign: body,
					performValidation: !isDraft,
				});
				if (res.isInvalid) {
					updateErrors(res.errors);
					updateLoading(actionType, false);
					return;
				}

				setIsDirty(false);
				await queryClient.invalidateQueries({
					queryKey: ['campaign', id],
				});
			} else {
				const res = await createDraftCampaign({
					campaign: body,
					performValidation: !isDraft,
				});
				if (res.isInvalid) {
					updateErrors(res.errors);
					updateLoading(actionType, false);
					return;
				}
				campaignId = res._id;
				setIsUpdate(true);
				setIsDirty(false);
				navigate(`/app/campaign/quests/${campaignId}/update`);
			}

			if (actionType === 'test') {
				await updateCampaignStatus(campaignId, {
					newStatus: Status.InReview,
				});

				await queryClient.invalidateQueries({
					queryKey: ['campaign', id],
				});
				navigate(`/app/campaign/quests/${campaignId}`);
			}
			analytics.track(TrackingEvents.CreateQuestActions, {
				actionType,
				campaignId,
			});
			if (isPreview) {
				window.open(`${QUEST_URL}/quest/${campaignId}`, '_blank');
			}
			await queryClient.invalidateQueries({
				queryKey: ['campaigns'],
			});
			await queryClient.invalidateQueries({
				queryKey: ['enterprise'],
			});
			if (actionType === 'launch') {
				setShowLaunch(true);
				return;
			}
			toast.success(
				actionType === 'test'
					? 'Campaign is ready for testing'
					: 'Draft saved successfully',
				{
					description:
						'Check out how does your campaign look on the platform.',
					action: (
						<Button onClick={() => previewCampaign(campaignId)}>
							{actionType === 'test' ? 'Test Campaign' : 'Preview'}
						</Button>
					),
					closeButton: true,
					duration: 2000,
				},
			);
		} catch (err) {
			console.log(err);
			handleErrorMessage(err);
		} finally {
			updateLoading(actionType, false);
		}
	};

	const launchCampaign = async () => {
		handleCampaignAction('launch');
	};
	const testCampaign = async () => {
		handleCampaignAction('test', true);
	};

	const saveDraft = async (isPreview: boolean) => {
		handleCampaignAction(!isPreview ? 'draft' : 'preview', isPreview);
	};

	const previewCampaign = async (campaignId: string) => {
		window.open(`${QUEST_URL}/quest/${campaignId || id}`, '_blank');
	};

	const uploadResource = async (
		file: any,
		type: 'banner' | 'whitelist' | 'seo',
	) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(file).then((res) => {
						resolve(res);
					}),
				]),
				{
					loading:
						type === 'banner'
							? 'Uploading banner...'
							: type === 'seo'
								? 'Uploading SEO image...'
								: 'Uploading image for your reward...',
					success:
						type === 'banner'
							? 'Banner uploaded successfully'
							: type === 'seo'
								? 'Image uploaded successfully'
								: 'Reward image uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	const updateLoading = (key: string, value: boolean) => {
		setIsLoading((prev) => ({ ...prev, [key]: value }));
	};

	const getErrorInfo = (errors_: any) => {
		const collectMessages = (obj) => {
			let messages = [];
			Object.values(obj).forEach((value) => {
				if (typeof value === 'object' && value !== null) {
					messages = messages.concat(collectMessages(value));
				} else if (typeof value === 'string' && value.trim() !== '') {
					messages.push(value);
				}
			});
			return messages;
		};

		const messages = collectMessages(errors_);
		return messages.join(', ');
	};

	const updateErrors = (errors_: any) => {
		adjustedIntercomLauncherPosition('140px');
		disclaimerToastIdRef.current = toast.warning(
			<div className="text-sm font-medium">
				<i className="bi-exclamation-triangle-fill me-1"></i>Campaign details
				is invalid.
				<p className="mt-1">Reason(s):</p>
				<ul className="text-xs">
					{getErrorInfo(errors_)
						.split(',')
						.map((i, idx) => (
							<li key={i}>{`${idx + 1}. ${i}`}</li>
						))}
				</ul>
			</div>,
			{
				closeButton: true,
				onDismiss: () => adjustedIntercomLauncherPosition('90px'),
				onAutoClose: () => adjustedIntercomLauncherPosition('90px'),
				className: 'w-[23.25rem]',
			},
		);

		setErrors(errors_);
	};

	return {
		previewCampaign,
		isLoading,
		launchCampaign,
		testCampaign,
		saveDraft,
	};
};
