import { CampaignRewardCategory, CampaignRewardType } from '../types';

export const rewardCategorys = [
	{
		label: 'ERC20 Tokens',
		icon: 'coin text-green-600',
		category: CampaignRewardCategory.Token,
		description: 'Highly Recommended',
	},
	{
		label: 'NFTs',
		image: 'https://static.highongrowth.xyz/enterprise/66e816db019c8468efc9c6ee/532215124fd44ee2b5f5f51114576cac.svg',
		category: CampaignRewardCategory.Nft,
		description: 'Make it fun',
	},
	{
		label: 'Discord Role',
		icon: 'discord text-indigo-500',
		category: CampaignRewardCategory.DiscordRole,
		description: 'Build Exclusivity',
	},
	{
		label: 'Official Points',
		icon: 'stars text-purple-600',
		category: CampaignRewardCategory.OfficialPoints,
		description: 'Earn points',
	},
	{
		label: 'Custom',
		icon: 'gift-fill text-orange-600',
		category: CampaignRewardCategory.Whitelist,
		description: 'Create unique rewards',
	},
];

export const getRewardMethods = (category: CampaignRewardCategory) => {
	return [
		{
			method: CampaignRewardType.Fcfs,
			label: '🏁 First come, First serve',
			description:
				'Rewards are unlocked for users who complete the quest first',
		},
		{
			method: CampaignRewardType.LuckyDraw,
			label: '🎟️ Raffle',
			description:
				category === CampaignRewardCategory.Token
					? 'Unclaimed prizes after the claim period will be redistributed to other participants in this lucky draw.'
					: 'Rewards are unlocked through a raffle for users after user completion',
		},
		{
			method: CampaignRewardType.SmartRaffle,
			label: '🔮 Smart Raffle',
			description:
				'Users instantly know if they have won a reward upon quest completion',
		},
		{
			method: CampaignRewardType.Leaderboard,
			label: '🏆 Leaderboard',
			description:
				'Uniform rewards are unlocked based on user leaderboard rankings',
		},
		{
			method: CampaignRewardType.TieredLeaderboard,
			label: '🌟 Tiered Leaderboard',
			description:
				'Rewards are distributed according to user positions on a tiered leaderboard',
		},
		{
			method: CampaignRewardType.Unlimited,
			label: '🔄 Unlimited Rewards',
			description:
				'Rewards are unlocked for every user who completes the quest',
		},
	];
};
