import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useCampaignReport } from './useCampaignReport';
import {
	getCampaignWinners,
	getCampaignWinnersCount,
} from '../../services/campaigns.service';
import { formatUserData } from '@/utils/parsers';
import { IWinner } from '../../components/campaign-report/components/approvals/WinnersColumns';

interface IWinners {
	users: {
		completedAt: Date;
		discordId: string;
		discordUsername: string;
		discordAvatar: string;
		events: {
			failedVerificationCount: number;
			isForceVerified: boolean;
			isVerified: boolean;
			isXpClaimed: boolean;
			metadata: { xp: number; templateFamily: string; userInput: any };
			name: string;
			taskId: string;
			verificationDoneAt: Date;
		}[];
		twitterId: string;
		twitterUsername: string;
		twitterAvatar: string;
		userAddresses: string[];
		xp: number;
		cycleNumber: number;
	}[];
}

export const useCampaignWinners = () => {
	const { id } = useCampaignReport();
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);

	const { data, isLoading } = useQuery<IWinners>({
		queryKey: ['campaign', 'winners', id, pagination, sorting],
		queryFn: () =>
			getCampaignWinners({
				campaignId: id,
				currentPage: pagination.pageIndex,
				perPage: pagination.pageSize,
			}),
	});

	const { data: count, isLoading: isCountLoading } = useQuery({
		queryKey: ['campaign', 'winners', 'count', id, pagination, sorting],
		queryFn: () =>
			getCampaignWinnersCount({
				campaignId: id,
				getTotalCount: true,
			}),
	});

	const users =
		data?.users?.map((i) => {
			const { username, avatar } = formatUserData(i);
			const baseUser: Omit<IWinner, `tier_${string}`> = {
				username,
				avatar,
				completedAt: i.completedAt,
				xp: i.xp,
				discord: i.discordUsername,
				twitter: i.twitterUsername,
				cycleNumber: i.cycleNumber,
			};

			const tierEntries = Object.entries(i).filter(([key]) =>
				key.startsWith('tier_'),
			);
			const userWithTiers = tierEntries.reduce(
				(acc, [key, value]) => ({
					...acc,
					[key]: value,
				}),
				baseUser,
			);

			return userWithTiers as IWinner;
		}) ?? [];

	return {
		winners: users ?? [],
		isLoading,
		totalCount: count?.count ?? 10,
		isCountLoading,
		pagination,
		setPagination,
		sorting,
		setSorting,
	};
};
