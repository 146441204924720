import { DialogHeader } from '@/components/ui/dialog';
import { SliderWithLabels } from '@/components/ui/slider';
import { getRewardMethods } from '@/features/campaigns/core/reward-defaults';
import { ICampaignReward } from '@/features/campaigns/types';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useMemo } from 'react';

const DepositTokenDialogWithSpeedrun = ({
	tokenReward,
	speedRunConfig,
	setSpeedRunConfig,
}: {
	tokenReward: ICampaignReward;
	speedRunConfig: {
		isEnrolled: boolean;
		speedRunAmount: number;
		questRewardPool: number;
		totalRewardPool?: number;
	};
	setSpeedRunConfig: any;
}) => {
	const { getChainName } = useGetSupportedChains({
		showAdditionalChains: false,
		fetchEnterpriseChains: false,
		showNonEVM: false,
	});

	const chainName = getChainName(
		tokenReward?.tokenReward?.chain ||
			tokenReward?.tokenReward?.chainId?.toString(),
	);

	const allocationBrackets = useMemo(() => {
		if (speedRunConfig?.totalRewardPool > 900) {
			return [0, 100, 200, 300];
		} else {
			if (speedRunConfig?.totalRewardPool > 400) {
				return [0, 50, 100, 150];
			} else {
				return [0, 0.1, 0.2];
			}
		}
	}, [speedRunConfig]);

	const updateSpeedRunAllocation = (e: number[]) => {
		console.log(e);
		const newSpeedRunAmount = Number(e[0].toFixed(3));

		setSpeedRunConfig((p: any) => ({
			...p,
			speedRunAmount: newSpeedRunAmount,
			questRewardPool: Number(
				(p.totalRewardPool - newSpeedRunAmount).toFixed(3),
			),
		}));
	};

	const rewardMethods = useMemo(() => {
		return getRewardMethods(tokenReward?.category);
	}, [tokenReward?.category]);

	return (
		<div>
			<div>
				<DialogHeader>
					<div>
						<h3 className="text-xl font-bold mb-2">
							<i className="bi-bank text-violet-600 mr-2"></i>
							Deposit Token Before Launching
						</h3>
						<p className="text-sm text-gray-500">
							Deposit the token you want to reward your users with for
							automatic reward claims.
						</p>
					</div>
				</DialogHeader>

				<div className="mt-4">
					<div className="grid grid-cols-2 p-4 mb-4 bg-slate-200 rounded-md">
						<div>
							<p className="text-sm font-medium mb-2">Reward Pool</p>
							<p className="text-2xl font-bold">
								${speedRunConfig?.questRewardPool}{' '}
								{tokenReward?.tokenReward?.tokenSymbol}
							</p>
						</div>
						<div className="pe-10">
							<p className="text-sm font-medium mb-2">Speedrun Pool</p>
							<p className="text-2xl font-bold">
								${speedRunConfig?.speedRunAmount}{' '}
								{tokenReward?.tokenReward?.tokenSymbol}
							</p>
						</div>
					</div>
					<div className="mb-5 bg-slate-100 rounded-lg px-3  pt-5 pb-10 ">
						<div className="text-sm font-medium mb-1">
							Allocate Token Rewards to Speedrun
						</div>
						<div className="mb-3 text-xs">
							Allocate a percentage of the total reward pool to the
							speedrun pool. The remaining amount will be distributed
							to the winners.
						</div>
						<SliderWithLabels
							min={0}
							max={allocationBrackets[allocationBrackets.length - 1]}
							step={allocationBrackets[1] - allocationBrackets[0]}
							labels={allocationBrackets?.map((i) =>
								i === 0 ? 'None' : `$${i}`,
							)}
							value={[speedRunConfig?.speedRunAmount]}
							onValueChange={(e) => updateSpeedRunAllocation(e)}
						/>
					</div>
					{tokenReward?.tokenReward?.tokenAmountPerUser && (
						<div className="flex justify-between items-center py-3">
							<div>
								<p className="text-sm text-gray-500 ">
									Reward per winner
								</p>
							</div>
							<div className="flex items-center justify-center text-sm font-medium">
								$
								{Number(
									(
										speedRunConfig?.questRewardPool /
										tokenReward?.numRewards
									).toFixed(3),
								)}{' '}
								{tokenReward?.tokenReward?.tokenSymbol} on{' '}
								{chainName}
							</div>
						</div>
					)}
					<div className="flex justify-between items-center py-3">
						<div>
							<p className="text-sm text-gray-500 ">
								Number of winners
							</p>
						</div>
						<div className="flex items-center justify-center text-sm font-medium">
							{tokenReward?.numRewards}
						</div>
					</div>

					<div className="flex justify-between items-center py-3">
						<div>
							<p className="text-sm text-gray-500 ">
								Distribution Method
							</p>
						</div>
						<div className="flex items-center justify-center text-sm font-medium">
							{
								rewardMethods.find(
									(i) => i.method === tokenReward?.method,
								)?.label
							}
						</div>
					</div>
				</div>
				<div className="bg-gray-100 w-full rounded-lg text-xs p-4 mt-10 font-medium">
					Please Note: Once you submit the tokens, you will not be able to
					change this reward from the product. If you want to get a refund,
					please fill the form.
				</div>
			</div>
		</div>
	);
};

export default DepositTokenDialogWithSpeedrun;
