import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectSeparator,
} from '@/components/ui/select';
import { getRewardMethods } from '@/features/campaigns/core/reward-defaults';
import {
	CampaignRewardCategory,
	CampaignRewardType,
} from '@/features/campaigns/types';
import { cn } from '@/lib/utils';
import { useMemo } from 'react';

const RewardMethodSelect = ({
	allowedMethods,
	value,
	setValue,
	error,
	errorText,
	rewardCategory,
}: {
	allowedMethods?: CampaignRewardType[];
	value: string;
	setValue: (value: string) => void;
	error?: boolean;
	errorText?: string | boolean;
	rewardCategory?: CampaignRewardCategory;
}) => {
	const methods = useMemo(() => {
		return getRewardMethods(rewardCategory)?.filter((i) =>
			allowedMethods?.length > 0 ? allowedMethods?.includes(i.method) : true,
		);
	}, [allowedMethods, rewardCategory]);

	const rewardMethods = useMemo(() => {
		return getRewardMethods(rewardCategory);
	}, [rewardCategory]);

	return (
		<>
			<Select value={value} onValueChange={(e) => setValue(e)}>
				<SelectTrigger
					aria-label="Area"
					className={cn('text-sm bg-white', error && 'border-destructive')}
				>
					<div className=" w-full py-1">
						<div className="flex items-center ">
							<div>
								{value
									? rewardMethods?.find((i) => i.method === value)
											?.label
									: 'Select Method'}
							</div>
						</div>
					</div>
				</SelectTrigger>
				<SelectContent>
					{methods?.map((i, index) => (
						<div key={i.label}>
							<SelectItem value={i.method} key={i.method}>
								<div>{i.label}</div>
								<p className="text-xs text-muted-foreground">
									{i.description}
								</p>
							</SelectItem>
							{index !== methods.length - 1 && <SelectSeparator />}
						</div>
					))}
				</SelectContent>
			</Select>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</>
	);
};

const learningTooltip = {
	[CampaignRewardType.Fcfs]: (
		<div>
			<p className="text-xs text-muted-foreground mt-2">
				Users who complete the action first will receive the reward.
			</p>
		</div>
	),
};

export default RewardMethodSelect;
